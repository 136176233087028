import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegisterTeacher from '@/components/layout/NewRegisterTeacher/newRegisterTeacher.vue'
import Teacher from '@/shared/models/teacher'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'EditarProfessor',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegisterTeacher.name]: NewRegisterTeacher
  },

  data: () => ({
    placeholder: 'Nome',
    title: 'Professor',
    teacher: new Teacher(),
    isNew: false
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Professores',
            href: this.$router.resolve({ name: 'Teacher' }).href
          },
          {
            text: 'Editar professor',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getById()
  },
  
  methods: {
    getById() {
      loading.push()
      TeacherRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.teacher = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professor', 'ERRO')
        })
    },

    update(teacher) {
      loading.push()
      TeacherRepository.Update(teacher)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Professor editado com sucesso', 'EDITAR PROFESSOR')
          Promise.resolve()
          return this.$router.replace({ name: 'Teacher' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar professor', 'ERRO')
        })
    }
  }
}
